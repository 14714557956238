<template>
	<div class="page discuss_page">
		<div class="discuss_typebox">
			<div class="block30"></div>
			<div class="w1200">
				<div class="titlename fontsize24" @click="sysgoback()">
					<img class="icon" src="../assets/static/leftgo.png"/>
					<div class="text">{{formObj.title || "河狸讨论区"}}</div>
				</div>
			</div>
		</div>
		<div class="discuss_conbox">
			<div class="w1200 flexdisplay flexbetween">
				<div class="leftbox">
					<div class="itemobj" v-for="(item,index) in dataList" :key="index">
						<div class="topbox" @click="clickarticleDetail(item.id)">
							<div class="titlebox fontsize24 clamp">{{item.title}}</div>
							<div class="descbox" v-if="item.hlUser">
								<div class="userinfo">
									<img :src="item.hlUser.hlImage || require('@/assets/logo.png')" class="logoimg">
									<div class="text clamp fontsize16">{{item.hlUser.nikename}}</div>
								</div>
								<div class="timer">{{item.createDate}}</div>
							</div>
							<div class="descbox" v-if="!item.hlUser">
								<div class="userinfo">
									<img :src="require('@/assets/logo.png')" class="logoimg">
									<div class="text clamp fontsize16">匿名</div>
								</div>
								<div class="timer">{{item.createDate}}</div>
							</div>
						</div>
						<div class="centerbox" @click="clickarticleDetail(item.id)">
							<div class="imgbox" v-if="item.imgUrl">
								<img :src="item.imgUrl || require('@/assets/logo.png')" class="logoimg">
							</div>
							<div class="conbox">
								<div class="text1 clamp3 fontsize14">{{item.contents}}</div>
								<div class="detailtext fontsize14">查看详情</div>
							</div>
						</div>
						<div class="bottombox fontsize14">
							<div class="inputbox">
								<input v-model="ctCommentInfo[index]" class="input" placeholder="期待你的神评论"/>
							</div>
							<div class="btn1" @click="handlecomment(item,index)">发布</div>
							<div class="numbox">
								<img src="../assets/static/plicon.png" class="icon">
								<div class="text fontsize14">{{item.plNum}}</div>
							</div>
						</div>
					</div>
					<div class="nolist fontsize16" v-if="dataList.length<=0">暂无相关记录，快去发布讨论吧！</div>
					<div class="fenyebox">
						 <el-pagination
						    @current-change="handleCurrentChange"
						    :current-page.sync="currentPage"
						    :page-size="pageSize"
						    layout="prev, pager, next, jumper"
						    :total="total">
						</el-pagination>
					</div>
				</div>
				<div class="rightbox">
					<div class="conbox">
						<div class="imgbox"><img :src="userInfo.hlImage || require('@/assets/logo.png')" class="img100"></div>
						<div class="username clamp fontsize20">{{userInfo.nikename}}</div>
						<div class="box1">
							<div class="item" @click="handleshowmyfabu(true)">
								<img src="../assets/static/Group427320837.png" class="icon">
								<div class="text fontsize16">我发布的话题</div>
							</div>
							<div class="item" @click="handleshowmyjoin(true)">
								<img src="../assets/static/Group427320838.png" class="icon">
								<div class="text fontsize16">我讨论的话题</div>
							</div>
						</div>
						<div class="btn1" @click="handleshowfabuhuati(true)">
							<img src="../assets/static/laba.png" class="icon">
							<div class="text fontsize16">发布话题</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 注册弹窗 -->
		<discussForm :isShow="showfabuhuati" :groupId="uid" @handleForm="handlediscussForm" titlename="发布话题"></discussForm>
		<discussForm :isShow="showfabuhuatiedit" :dataobj="dataobj" :istype="'edit'" :groupId="uid" @handleForm="handlediscussedit" titlename="编辑话题"></discussForm>
		<discussList ref="myset" :isShow="showmyfabu" :groupId="uid" :istype="'myset'" @handleedit="handlefabuedit" @handleForm="handlediscussForm" titlename="我发布的话题"></discussList>
		<discussList ref="mysay" :isShow="showmyjoin" :groupId="uid" :istype="'mysay'" @handledisdetail="handledisdetail" @handleForm="handlediscussForm" titlename="我讨论的话题"></discussList>
	</div>
</template>

<script>
import {mapState,mapMutations} from 'vuex'
import {showLoading,hideLoading} from '@/common/loading.js';
import  discussForm  from "../components/registerForm/discussForm.vue";
import  discussList  from "../components/registerForm/discussList.vue";
export default {
	components: {
		discussForm,
		discussList
	},
	data() {
		return {
			uid:0,//ID
			formObj: {},//详情
			dataList:[],
			currentPage:1,
			pageSize:10,
			total:0,//总数
			showfabuhuati:false,//发布话题
			showfabuhuatiedit:false,//编辑话题
			dataobj:{},//编辑的话题详情
			showmyfabu:false,//我发布话题
			showmyjoin:false,//我参与话题
			ctCommentInfo:[],//评论
		};
	},
	mounted: function() {
		if(this.$route.query&&this.$route.query.uid){
			this.uid = parseInt(this.$route.query.uid)
			this.getDataDetail()
		}else{
			this.$alert('参数ID丢失，请返回重试！', '提示', {
			    confirmButtonText: '好的',
			    callback: action => {
					this.sysgoback()
			    }
			});
		}
	},
	computed: {
		...mapState(['userInfo', 'isLogin']),
	},
	methods: {
		...mapMutations(["SET_USERINFO",'SET_ISLOGIN']),
		//编辑我的文章
		handlefabuedit(item){
			this.dataobj = item
			this.showfabuhuatiedit = true
		},
		//关闭编辑的
		handlediscussedit(){
			this.showfabuhuatiedit = false
		},
		//发布评论
		handlecomment(row,plindex){
			var _this = this
			if(!this.ctCommentInfo[plindex]){
				this.$message.error("请输入评论内容！")
				return;
			}
			var params = {}
			params["comment"] = this.ctCommentInfo[plindex]
			params["userUuid"] = this.userInfo.uuid
			params["groupId"] = this.uid
			params["objId"] = row.id
			showLoading()
			this.$http.post('app/content/ctCommentInfo/save', params).then(function(res) {
				hideLoading()
				//数据处理
				if (res.code == 200) {
					// _this.$alert('发表评论成功，请等待审核！', '温馨提示', {
					// 	confirmButtonText: '确定',
					// 	callback: action => {
					// 		//_this.ctCommentInfo[plindex] = ""
					// 	}
					// });
					_this.$message.success('发表评论成功')
					var ctCommentInfo = []
					_this.dataList.forEach((item,index)=>{
						ctCommentInfo.push("")
					})
					_this.ctCommentInfo = ctCommentInfo
					//刷新
					_this.currentPage = 1
					_this.getPagedata()
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//我的发布弹窗
		handleshowmyfabu(type){
			var _this = this
			this.showmyfabu = type
			if(type){
				setTimeout(function(){
					_this.$refs.myset.refreshdata()
				},500)
			}
		},
		//我讨论的话题
		handleshowmyjoin(type){
			var _this = this
			this.showmyjoin = type
			if(type){
				setTimeout(function(){
					_this.$refs.mysay.refreshdata()
				},500)
			}
		},
		//发布话题成功
		handlediscussForm(type){
			if(this.showfabuhuati || this.showfabuhuatiedit){
				//刷新
				this.currentPage = 1
				this.getPagedata()
			}
			this.showfabuhuati = false
			this.showfabuhuatiedit = false
			this.showmyfabu = false
			this.showmyjoin = false
		},
		//发布弹窗
		handleshowfabuhuati(type){
			this.showfabuhuati = type
		},
		//下一页
		handleCurrentChange(currentPage){
			this.currentPage = currentPage
			this.getPagedata()
		},
		//目录
		getPagedata() {
			var _this = this
			var params = {}
			params["currentPage"] = this.currentPage
			params["pageSize"] = this.pageSize
			params["groupId"] = this.uid
			this.$http.post('app/content/ctDiscussInfo/index', params).then(function(res) {
				//数据处理
				if (res.code == 200) {
					var records = res.data.records
					var ctCommentInfo = []
					if(records.length>0){
						records.forEach((item,index)=>{
							if(item.imgUrlList){
								item["imagesList"] = JSON.parse(item.imgUrlList)
								item["imgUrl"] = item.imagesList[0]
							}
							ctCommentInfo.push("")
						})
					}
					_this.ctCommentInfo = ctCommentInfo
					_this.dataList = records
					_this.total = res.data.total;
				} else {
					_this.$confirm(res.message, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
				}
			})
		},
		//我讨论话题跳转详情
		handledisdetail(item){
			this.$router.push({ path: '/discussDetail', query: {uid: item.objId }})
		},
		//跳转详情
		clickarticleDetail(uid){
			this.$router.push({ path: '/discussDetail', query: {uid: uid }})
		},
		//获取详情
		getDataDetail() {
			var discussGroup = localStorage.getItem("discussGroup")
			if(discussGroup){
				this.formObj = JSON.parse(discussGroup)
				//目录
				this.getPagedata()
			}else{
				this.$alert('参数对象丢失，请返回重试！', '提示', {
				    confirmButtonText: '好的',
				    callback: action => {
						this.sysgoback()
				    }
				});
			}
		},
		
	}
};
</script>
<style lang="scss" scoped>
	.fenyebox{
		height: 88px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.discuss_typebox{
		width: 100%;
		background-color: #f8f8f8;
		.titlename{
			display: flex;
			// align-items: center;
			color: #222;
			cursor: pointer;
			.icon{
				width: 28px;
				height: 28px;
				margin-right: 8px;
				margin-top:4px;
			}
			.text{
				padding-bottom: 8px;
				border-bottom: 1px solid #222;
			}
		}
	}
	.discuss_conbox{
		width: 100%;
		padding: 28px 0;
		.leftbox{
			// width: 836px;
			flex: 1;
			.itemobj{
				width: 100%;
				margin-top: 18px;
				cursor: pointer;
				.topbox{
					display: flex;
					align-items: center;
					.titlebox{
						color: #000;
						width: 558px;
					}
					.descbox{
						flex: 1;
						display: flex;
						align-items: center;
						justify-content: flex-end;
						.userinfo{
							flex: 1;
							flex-shrink: 0;
							display: flex;
							align-items: center;
							.logoimg{
								width: 28px;
								height: 28px;
								border-radius: 28px;
								background-color: #f8f8f8;
								flex-shrink: 0;
							}
							.text{
								flex: 1;
								margin-left: 8px;
								color: #666;
							}
						}
						.timer{
							text-align: right;
							flex-shrink: 0;
							color: #666;
						}
					}
				}
				.centerbox{
					width: 100%;
					margin-top: 10px;
					display: flex;
					align-items: center;
					.imgbox{
						width: 126px;
						height: 88px;
						flex-shrink: 0;
						margin-right: 20px;
						border-radius: 8px;
						overflow: hidden;
					}
					.conbox{
						flex: 1;
						min-height: 88px;
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						.text1{
							color: #666;
							line-height: 180%; /* 25.2px */
						}
						.detailtext{
							width: 100%;
							text-align: right;
							color: #3191FF;
						}
					}
				}
				.bottombox{
					width: 100%;
					padding: 18px 0;
					display: flex;
					align-items: center;
					border-bottom: 1px solid #E6E6E6;
					.inputbox{
						flex: 1;
						padding: 0 18px;
						box-sizing: border-box;
						border-radius: 8px;
						background: #F7F8FB;
						.input{
							width: 100%;
							height: 36px;
							line-height: 36px;
						}
					}
					.btn1{
						width: 60px;
						height: 36px;
						line-height: 36px;
						text-align: center;
						border-radius: 8px;
						background: #3191FF;
						color: #FFF;
						margin: 0 10px;
						flex-shrink: 0;
					}
					.numbox{
						width: 58px;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;
						.icon{
							width: 24px;
							height: 24px;
						}
						.text{
							color: #000;
						}
					}
				}
			}
		}
		.rightbox{
			width: 324px;
			margin-left: 38px;
			flex-shrink: 0;
			margin-top: 38px;
			.conbox{
				padding: 28px;
				display: flex;
				flex-direction: column;
				align-items: center;
				background: #F7F8FB;
				border-radius: 24px;
				.imgbox{
					width: 100px;
					height: 100px;
					flex-shrink: 0;
					border-radius: 100px;
					overflow: hidden;
					margin-top: -58px;
				}
				.username{
					width: 100%;
					color: #000;
					margin-top: 20px;
					text-align: center;
				}
				.box1{
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-top: 16px;
					.item{
						width: 50%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						cursor: pointer;
						.icon{
							width: 68px;
							height: 68px;
							flex-shrink: 0;
						}
						.text{
							color: #000;
							margin-top: 15px;
						}
					}
				}
				.btn1{
					width: 168px;
					height: 44px;
					margin-top: 38px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 22px;
					background: #222;
					cursor: pointer;
					.icon{
						width: 24px;
						height: 24px;
						flex-shrink: 0;
					}
					.text{
						color: #FFF;
						margin-left: 8px;
					}
				}
			}
		}
	}
</style>
